import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const SOFTCORNERLandingPage = () => (
  <PhysicianLandingPage
    physician="Morgan Miller"
    institution="Soft Corner"
    referralCode="SOFTCORNER"
    physicianURL="https://softcornermidwifery.com/"
  />
)

export default SOFTCORNERLandingPage
